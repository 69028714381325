import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import ExpandableCard from 'components/card/ExpandableCard';
import NotificationToggles from './NotificationToggles';
import NotificationDescription from './NotificationDescription';
import { notificationType } from 'global/services/NotificationTypeService';
import { ISSUE_TYPE } from 'global/services/IssueTypeService';

import "./NotificationsManagement.scss";

class NotificationsManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: false
        }
    }

    expandClick() {
        this.setState({
            expand: !this.state.expand
        })
    }

    getRule(reason, issueType) {
        return this.props.notificationRules
            .filter(rule => rule.notificationReason === reason && rule.issueType === issueType)
            .map(rule => {
                return {
                    id: rule.id,
                    fallbackOnSalesRep: rule.fallbackOnSalesRep,
                    contactGroups: rule.contactGroups.map(group => group.id)
                }
            })[0];
    }

    getIssueTypeRule(issueType) {
        return this.props.notificationIssueTypeRules.filter(rule => rule.issueType === issueType)[0];
    }

    render() {
        const resolutionRule1 = "All recipients of business rules and all recipients of prior escalation notifications, in addition to configured recipients on the left";
        const resolutionRule2 = "All recipients of business rules, in addition to configured recipients on the left";

        return (
            <div className="notifications-management">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="header">
                        Notifications Management
                    </div>
                    <div className="expand" onClick={this.expandClick.bind(this)}>
                        Expand All
                        {this.state.expand ?
                            <FontAwesomeIcon icon={faChevronUp} className="expand-arrow" /> :
                            <FontAwesomeIcon icon={faChevronDown} className="expand-arrow" />
                        }
                    </div>
                </div>
                <ExpandableCard title="Power Issue" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.updateRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.POWER_STATE}
                        notificationTypes={[notificationType.VALUE_MISMATCH_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISMATCH_WARNING, ISSUE_TYPE.POWER_STATE)}
                        escalationRule={this.getRule(notificationType.VALUE_MISMATCH_ESCALATION, ISSUE_TYPE.POWER_STATE)}
                        resolvedRule={this.getRule(notificationType.VALUE_MISMATCH_RESOLVED, ISSUE_TYPE.POWER_STATE)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.POWER_STATE)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule1}
                        businessRules={["No active leg: Sales Representative"]}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.POWER_STATE)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Mode Issue" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.UNIT_MODE}
                        notificationTypes={[notificationType.VALUE_MISMATCH_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISMATCH_WARNING, ISSUE_TYPE.UNIT_MODE)}
                        escalationRule={this.getRule(notificationType.VALUE_MISMATCH_ESCALATION, ISSUE_TYPE.UNIT_MODE)}
                        resolvedRule={this.getRule(notificationType.VALUE_MISMATCH_RESOLVED, ISSUE_TYPE.UNIT_MODE)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.UNIT_MODE)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule1}
                        businessRules={["No active leg: Sales Representative"]}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.UNIT_MODE)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Temperature Issue" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.TEMPERATURE}
                        notificationTypes={[notificationType.VALUE_MISMATCH_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISMATCH_WARNING, ISSUE_TYPE.TEMPERATURE)}
                        escalationRule={this.getRule(notificationType.VALUE_MISMATCH_ESCALATION, ISSUE_TYPE.TEMPERATURE)}
                        resolvedRule={this.getRule(notificationType.VALUE_MISMATCH_RESOLVED, ISSUE_TYPE.TEMPERATURE)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.TEMPERATURE)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule1}
                        businessRules={["No active leg: Sales Representative"]}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.TEMPERATURE)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Silent Units" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.SILENT}
                        notificationTypes={[notificationType.SILENT_UNIT_WARNING, notificationType.SILENT_UNIT_ESCALATION, notificationType.SILENT_UNIT_RESOLVED]}
                        warningRule={this.getRule(notificationType.SILENT_UNIT_WARNING, ISSUE_TYPE.SILENT)}
                        escalationRule={this.getRule(notificationType.SILENT_UNIT_ESCALATION, ISSUE_TYPE.SILENT)}
                        resolvedRule={this.getRule(notificationType.SILENT_UNIT_RESOLVED, ISSUE_TYPE.SILENT)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.SILENT)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule1}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.SILENT)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                        businessRules={["No active leg: Sales Representative"]}
                    />
                </ExpandableCard>
                <ExpandableCard title="Missing Temperature Configuration" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.DATA_ENTRY_ERROR}
                        notificationTypes={[notificationType.VALUE_MISSING_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISSING_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISSING_WARNING, ISSUE_TYPE.DATA_ENTRY_ERROR)}
                        noEscalation
                        resolvedRule={this.getRule(notificationType.VALUE_MISSING_RESOLVED, ISSUE_TYPE.DATA_ENTRY_ERROR)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.DATA_ENTRY_ERROR)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule2}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.DATA_ENTRY_ERROR)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                        businessRules={["No active leg: Sales Representative"]}
                    />
                </ExpandableCard>
                <ExpandableCard title="Inactive Trailers" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.INACTIVITY}
                        notificationTypes={[notificationType.INACTIVITY_WARNING, null, notificationType.INACTIVITY_RESOLVED]}
                        warningRule={this.getRule(notificationType.INACTIVITY_WARNING, ISSUE_TYPE.INACTIVITY)}
                        noEscalation
                        resolvedRule={this.getRule(notificationType.INACTIVITY_RESOLVED, ISSUE_TYPE.INACTIVITY)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.INACTIVITY)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule2}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.INACTIVITY)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                        businessRules={["Trailer has order, but no active leg: Sales Representative and Planners", "Trailer has no order: Planners"]}
                    />
                </ExpandableCard>
                <ExpandableCard title="Detention Issues" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.DETENTION}
                        notificationTypes={[notificationType.DETENTION_WARNING, null, notificationType.DETENTION_RESOLVED]}
                        warningRule={this.getRule(notificationType.DETENTION_WARNING, ISSUE_TYPE.DETENTION)}
                        noEscalation
                        resolvedRule={this.getRule(notificationType.DETENTION_RESOLVED, ISSUE_TYPE.DETENTION)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.DETENTION)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule2}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.DETENTION)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                        businessRules={["Trailer has order, but no active leg: Sales Representative and Planners"]}
                    />
                </ExpandableCard>
                <ExpandableCard title="Fuel Level" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.LOW_FUEL_ALARM}
                        notificationTypes={[notificationType.LOW_FUEL_WARNING, notificationType.LOW_FUEL_ESCALATION, notificationType.LOW_FUEL_RESOLVED]}
                        warningRule={this.getRule(notificationType.LOW_FUEL_WARNING, ISSUE_TYPE.LOW_FUEL_ALARM)}
                        escalationRule={this.getRule(notificationType.LOW_FUEL_ESCALATION, ISSUE_TYPE.LOW_FUEL_ALARM)}
                        resolvedRule={this.getRule(notificationType.LOW_FUEL_RESOLVED, ISSUE_TYPE.LOW_FUEL_ALARM)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.LOW_FUEL_ALARM)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule1}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.LOW_FUEL_ALARM)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Alarms" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.ALARM}
                        notificationTypes={[notificationType.MAINTENANCE, null, null]}
                        warningRule={this.getRule(notificationType.MAINTENANCE, ISSUE_TYPE.ALARM)}
                        noEscalation
                        noResolved
                        contactGroups={this.props.contactGroups}
                    />
                    <NotificationDescription
                        rule={this.getIssueTypeRule(ISSUE_TYPE.ALARM)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                        businessRules={["No active leg: Sales Representative"]}
                    />
                </ExpandableCard>
                <ExpandableCard title="Detached Trailers" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.DETACHED}
                        notificationTypes={[notificationType.DETACHED_WARNING, null, notificationType.DETACHED_RESOLVED]}
                        warningRule={this.getRule(notificationType.DETACHED_WARNING, ISSUE_TYPE.DETACHED)}
                        noEscalation
                        resolvedRule={this.getRule(notificationType.DETACHED_RESOLVED, ISSUE_TYPE.DETACHED)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.DETACHED)}
                    />
                    <NotificationDescription
                        rule={this.getIssueTypeRule(ISSUE_TYPE.DETACHED)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Missing Trailers" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.MISSING}
                        notificationTypes={[notificationType.MISSING_WARNING, null, notificationType.MISSING_RESOLVED]}
                        warningRule={this.getRule(notificationType.MISSING_WARNING, ISSUE_TYPE.MISSING)}
                        resolvedRule={this.getRule(notificationType.MISSING_RESOLVED, ISSUE_TYPE.MISSING)}
                        noEscalation
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.MISSING)}
                    />
                    <NotificationDescription
                        rule={this.getIssueTypeRule(ISSUE_TYPE.MISSING)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Mismatched Trailers" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.LOCATION_MISMATCH}
                        notificationTypes={[notificationType.LOCATION_MISMATCH_WARNING, notificationType.LOCATION_MISMATCH_ESCALATION, notificationType.LOCATION_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.LOCATION_MISMATCH_WARNING, ISSUE_TYPE.LOCATION_MISMATCH)}
                        escalationRule={this.getRule(notificationType.LOCATION_MISMATCH_ESCALATION, ISSUE_TYPE.LOCATION_MISMATCH)}
                        resolvedRule={this.getRule(notificationType.LOCATION_MISMATCH_RESOLVED, ISSUE_TYPE.LOCATION_MISMATCH)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.LOCATION_MISMATCH)}
                    />
                    <NotificationDescription
                        rule={this.getIssueTypeRule(ISSUE_TYPE.LOCATION_MISMATCH)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
                <ExpandableCard title="Trailers with claim" expand={ this.state.expand }>
                    <NotificationToggles
                        addRule={ this.props.addRule }
                        updateRule={ this.props.updateRule }
                        issueType={ ISSUE_TYPE.CLAIM }
                        notificationTypes={ [notificationType.CLAIM_WARNING, notificationType.CLAIM_ESCALATION, notificationType.CLAIM_RESOLVED] }
                        warningRule={ this.getRule(notificationType.CLAIM_WARNING, ISSUE_TYPE.CLAIM) }
                        escalationRule={ this.getRule(notificationType.CLAIM_ESCALATION, ISSUE_TYPE.CLAIM) }
                        resolvedRule={ this.getRule(notificationType.CLAIM_RESOLVED, ISSUE_TYPE.CLAIM) }
                        contactGroups={ this.props.contactGroups }
                        issueTypeRule={ this.getIssueTypeRule(ISSUE_TYPE.CLAIM) }
                    />
                    <NotificationDescription
                        rule={ this.getIssueTypeRule(ISSUE_TYPE.CLAIM) }
                        updateIssueTypeRule={ this.props.updateIssueTypeRule }
                    />
                </ExpandableCard>
                <ExpandableCard title="Trailer Audit" expand={ this.state.expand }>
                    <NotificationToggles
                        addRule={ this.props.addRule }
                        updateRule={ this.props.updateRule }
                        notificationTypes={ [notificationType.TRAILER_REPORT, null, null] }
                        warningRule={ this.getRule(notificationType.TRAILER_REPORT) }
                        noEscalation
                        noResolved
                        noTooltip
                        noHeader
                        contactGroups={ this.props.contactGroups.filter(group => !group.fallbackOnDivision && !group.fallbackOnSalesRep) }
                    />
                </ExpandableCard>
                <ExpandableCard title="Inactive in Yard Trailers" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={ISSUE_TYPE.INACTIVE_IN_YARD}
                        notificationTypes={[notificationType.INACTIVE_IN_YARD_WARNING, null, notificationType.INACTIVE_IN_YARD_RESOLVED]}
                        warningRule={this.getRule(notificationType.INACTIVE_IN_YARD_WARNING, ISSUE_TYPE.INACTIVE_IN_YARD)}
                        noEscalation
                        resolvedRule={this.getRule(notificationType.INACTIVE_IN_YARD_RESOLVED, ISSUE_TYPE.INACTIVE_IN_YARD)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(ISSUE_TYPE.INACTIVE_IN_YARD)}
                    />
                    <NotificationDescription
                        resolutionRule={resolutionRule1}
                        rule={this.getIssueTypeRule(ISSUE_TYPE.INACTIVE_IN_YARD)}
                        updateIssueTypeRule={this.props.updateIssueTypeRule}
                    />
                </ExpandableCard>
            </div>
        )
    }
}

export default NotificationsManagement;
