import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router';

import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';
import TooltipComp from 'components/tooltip/TooltipComp';
import CanAccess from 'components/can-access/CanAccess';
import { groupedByIssues, issuesStatusUrl } from 'global/constants';
import { getDayBefore } from 'global/services/DateTimeService';
import { ISSUE_TYPE } from 'global/services/IssueTypeService';
import QueryParamService from 'global/services/QueryParamService';
import { PERMISSION } from 'global/utils/auth';
import IssueBox from "./IssueBox";

import './Dashboard.scss';

const powerTooltip = "The table displays discrepancies in case TK is reporting that the trailer is on or off and it does not match TMW.";
const unauthorizedUseTooltip = "The table displays discrepancies in case TK is reporting that the trailer is on and the trailer is not on a load.";
const modeTooltip = "The table displays a list of issues when the Mode reading from TK is not matching the Mode configuration in TMW.";
const tempTooltip = "The table displays potential temperature issues when reefer temperatures from TK are detected to fall outside of the minimum and maximum temperature thresholds set in TMW.";
const tempMissingTooltip = "Table shows list of trailers that do not have Min and Max temperature set within TMW. For these trailers this tool will not be useful as it cannot automatically detect issues.";
const fleetOverviewTooltip = "The Dashboard view displays a real-time overview of reefer issue statistics for the past 24 hours for the trailers in the system."
const detentionTooltip = "The table displays a list of trailers that are detained."
const fuelLevelIssuesTooltip = "The table displays a list of trailers with low fuel levels.";
const detachedTrailersTooltip = "The table shows list of trailers which are the responsibility of drivers currently on home time that are not in close proximity of the truck.";
const missingTrailersTooltip = "The table shows list of trailers which are the responsibility of drivers currently on home time that are not in close proximity of the truck and did not report in the last 24 hours.";
const mismatchedTrailersTooltip = "The table shows a list of trailers for which the locations in TMW and TK are not within a 1 mile radius of each other."
const claimTooltip = "The table displays a list of trailers with a claim.";

const CACHE = {};

class Dashboard extends Component {
    static contextType = AuthContext;
    interval = 0;

    constructor(props) {
        super(props);

        let activeIssues = [];
        let resolvedIssues = [];
        let snoozedIssues = [];

        for (const type in ISSUE_TYPE) {
            activeIssues[type] = 0;
            resolvedIssues[type] = 0;
            snoozedIssues[type] = 0;
        }

        activeIssues['UNAUTHORIZED_USE'] = 0;
        resolvedIssues['UNAUTHORIZED_USE'] = 0;
        snoozedIssues['UNAUTHORIZED_USE'] = 0;

        this.state = {
            groupedIssues: {
                "POWER_STATE": [],
                "UNAUTHORIZED_USE": [],
                "TEMPERATURE": [],
                "UNIT_MODE": [],
                "DATA_ENTRY_ERROR": [],
                "DETENTION" : [],
                "LOW_FUEL_ALARM": [],
                "DETACHED": [],
                "MISSING": [],
                "LOCATION_MISMATCH": [],
                "CLAIM": []
            },
            countActiveIssues: activeIssues,
            countResolvedIssues: resolvedIssues,
            countSnoozedIssues: snoozedIssues,
            loaded: false,
            account: props.account,
        }

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        let queryString = window.location.search.slice(1);
        QueryParamService.addTeamsToQueryString(queryString, selectedTeams);
    }

    async componentDidMount() {
        await this.fetchShortcutModuleData();
        this.interval = setInterval(async () => await this.fetchShortcutModuleData(), 60000);
    }

    async componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            await this.fetchShortcutModuleData();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async fetchShortcutModuleData() {
        this.setState({
            loaded: false
        });

        await this.fetchIssueNumber();
        await this.fetchIssuesStatus();

        this.setState({
            loaded: true
        });
    }

    async fetchIssuesStatus() {
        let cachingIndex = this.context.team.slice().join('_');

        if (CACHE[cachingIndex] !== undefined) {
            this.setState({
                countActiveIssues: CACHE[cachingIndex].activeIssues ? CACHE[cachingIndex].activeIssues : [],
                countResolvedIssues: CACHE[cachingIndex].resolvedIssues ? CACHE[cachingIndex].resolvedIssues : [],
                countSnoozedIssues: CACHE[cachingIndex].snoozedIssues ? CACHE[cachingIndex].snoozedIssues : []
            });
        }

        const response = await this.context.get(issuesStatusUrl, { endTimeFrom: getDayBefore(new Date()) });

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        let activeIssues = Array.from([]);
        let resolvedIssues = Array.from([]);
        let snoozedIssues = Array.from([]);

        for (const entity of response) {
            activeIssues[entity.issueType] = entity.countActiveIssues;
            resolvedIssues[entity.issueType] = entity.countResolvedIssues;
            snoozedIssues[entity.issueType] = entity.countSnoozedIssues;
        }

        CACHE[cachingIndex] = {
            ...CACHE[cachingIndex],
            activeIssues: activeIssues,
            resolvedIssues: resolvedIssues,
            snoozedIssues: snoozedIssues
        };

        this.setState({
            countActiveIssues: activeIssues,
            countResolvedIssues: resolvedIssues,
            countSnoozedIssues: snoozedIssues
        });
    }

    async fetchIssueNumber() {
        let cachingIndex = this.context.team.slice().join('_');

        if (CACHE[cachingIndex] !== undefined) {
            this.setState({
                groupedIssues: CACHE[cachingIndex].groupedIssues ? CACHE[cachingIndex].groupedIssues : []
            });
        }

        const response = await this.context.get(groupedByIssues, {});

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        CACHE[cachingIndex] = {
            ...CACHE[cachingIndex],
            groupedIssues: { ...this.state.groupedIssues, ...response }
        };

        this.setState(prevState => ({
            groupedIssues: { ...prevState.groupedIssues, ...response }
        }));
    }

    render() {
        return (
            <div className="container-fluid page dashboard">
                <div className="row">
                    <div className="col">
                        <h1 className="page-title">Fleet overview <TooltipComp customClassName="fleet-tooltip" description={fleetOverviewTooltip} position="center"></TooltipComp> &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.loaded} /></h1>
                    </div>
                </div>
                <div className="row">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.POWER_STATE.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.POWER_STATE] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.POWER_STATE] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.POWER_STATE] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.POWER_STATE] }
                                    pathname="power?order=true"
                                    title="Power Issues:"
                                    tooltip={ powerTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [ PERMISSION.ISSUE.READ, PERMISSION.ISSUE.POWER_STATE.READ ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues['UNAUTHORIZED_USE'] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues['UNAUTHORIZED_USE'] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues['UNAUTHORIZED_USE'] }
                                    groupedIssues={ this.state.groupedIssues['UNAUTHORIZED_USE'] }
                                    pathname="power?order=false"
                                    title="Unauthorized Use:"
                                    tooltip={ unauthorizedUseTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.TEMPERATURE.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.TEMPERATURE] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.TEMPERATURE] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.TEMPERATURE] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.TEMPERATURE] }
                                    pathname="temperature"
                                    title="Temperature Issues:"
                                    tooltip={ tempTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.UNIT_MODE.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.UNIT_MODE] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.UNIT_MODE] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.UNIT_MODE] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.UNIT_MODE] }
                                    pathname="mode"
                                    title="Mode issues:"
                                    tooltip={ modeTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.DATA_ENTRY_ERROR.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.DATA_ENTRY_ERROR] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.DATA_ENTRY_ERROR] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.DATA_ENTRY_ERROR] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.DATA_ENTRY_ERROR] }
                                    pathname="temperature-missing"
                                    title="Missing temp. config.:"
                                    tooltip={ tempMissingTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.LOW_FUEL_ALARM.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.LOW_FUEL_ALARM] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.LOW_FUEL_ALARM] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.LOW_FUEL_ALARM] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.LOW_FUEL_ALARM] }
                                    pathname="low-fuel"
                                    title="Fuel level issues:"
                                    tooltip={ fuelLevelIssuesTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.DETENTION.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.DETENTION] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.DETENTION] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.DETENTION] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.DETENTION] }
                                    pathname="detention"
                                    title="Detained trailers:"
                                    tooltip={ detentionTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.MISSING.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.MISSING] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.MISSING] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.MISSING] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.MISSING] }
                                    pathname="missing"
                                    title="Missing trailers:"
                                    tooltip={ missingTrailersTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.DETACHED.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.DETACHED] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.DETACHED] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.DETACHED] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.DETACHED] }
                                    pathname="detached"
                                    title="Detached trailers:"
                                    tooltip={ detachedTrailersTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.LOCATION_MISMATCH.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.LOCATION_MISMATCH] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.LOCATION_MISMATCH] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.LOCATION_MISMATCH] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.LOCATION_MISMATCH] }
                                    pathname="mismatched"
                                    title="Mismatched trailers:"
                                    tooltip={ mismatchedTrailersTooltip }
                                />
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.CLAIM.READ] }
                            yes={
                                <IssueBox
                                    activeIssuesCount={ this.state.countActiveIssues[ISSUE_TYPE.CLAIM] }
                                    snoozedIssuesCount={ this.state.countSnoozedIssues[ISSUE_TYPE.CLAIM] }
                                    resolvedIssuesCount={ this.state.countResolvedIssues[ISSUE_TYPE.CLAIM] }
                                    groupedIssues={ this.state.groupedIssues[ISSUE_TYPE.CLAIM] }
                                    pathname="claim"
                                    title="Trailers with claim:"
                                    tooltip={ claimTooltip }
                                />
                            }
                        />
                    </div>
            </div>
        )
    }
}

export default withRouter(withAITracking(reactPlugin, Dashboard, "Dashboard"));
