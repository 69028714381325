import { issueTypeMap } from "global/services/IssueTypeService";
import {
  addSilentUnitThresholdToDate,
  formatDate,
  getCurrentDate,
  getDateWithOffset,
  getDifferenceBetweenDates
} from 'global/services/DateTimeService';
import { RESOLVED_STATES } from "global/constants";

function mapSeenBy(issue, seenBy) {
  let returnJson = {
    seenBeforeResolution: [],
    seenAfterResolution: []
  };

  if (issue.type === "DETENTION" || issue.type === "INACTIVITY") {
    if (RESOLVED_STATES.indexOf(issue.state) !== -1) {
      seenBy.forEach(activity => {
        if (activity.time > issue.endTime) {
          returnJson.seenAfterResolution.push(activity);
        } else {
          returnJson.seenBeforeResolution.push(activity);
        }
      });
    } else {
      returnJson.seenBeforeResolution = seenBy;
    }
  } else {
    if (RESOLVED_STATES.indexOf(issue.state) !== -1 || (issue.assignment && issue.assignment.active === false) || (issue.leg && issue.leg.active === false)) {
      seenBy.forEach(activity => {
        if (activity.time > issue.endTime) {
          returnJson.seenAfterResolution.push(activity);
        } else {
          returnJson.seenBeforeResolution.push(activity);
        }
      });
    } else {
      returnJson.seenBeforeResolution = seenBy;
    }
  }
  return returnJson;
}

export function mapIssuesFromResponse(data, isMaintenance) {
  const issues = [];
  data.forEach(obj => {
    let measurement = obj.latestMeasurement;
    if(!measurement || Object.keys(measurement).length === 0) {
      measurement = {
        temperatureState: {},
        modeState: {},
        alarmState: {},
        powerState: {},
        engineState: {}
      };
    }
    let notification = { alerts: null, notified: null, o_notified: null, snoozed: null };
    let latestWarningNotification = obj.latestWarningNotification || {};
    let latestEscalationNotification = obj.latestEscalationNotification || {};

    if(obj.latestWarningNotification) {
      latestWarningNotification = {
        ...obj.latestWarningNotification,
        alerts: "Warning",
        type: "Warning",
        notified: obj.latestWarningNotification.time ? new Date(obj.latestWarningNotification.time) : "",
        time: new Date(obj.latestWarningNotification.time),
        emails: obj.latestWarningNotification.contactInfos.map(x => x.email),
      }
    }

    if (obj.latestEscalationNotification) {
      latestEscalationNotification = {
        ...obj.latestEscalationNotification,
        alerts: "Escalation",
        type: "Escalation",
        o_notified: new Date(obj.latestEscalationNotification.time) || null,
        time: new Date(obj.latestEscalationNotification.time),
        emails: obj.latestEscalationNotification.contactInfos.map(x => x.email)
      }
    }

    notification = { ...notification, ...latestWarningNotification, ...latestEscalationNotification };
    if (obj.issue && obj.issue.state === "SNOOZED") {
      notification = {
        ...notification,
        snoozed: new Date(obj.issue.stateUpdated)
      }
    }

    const issue = obj.issue;
    if(issue.assignment) {
      if(issue.assignment.temperatureMin === null || issue.assignment.temperatureMin === undefined) {
        if(issue.metaData.temperatureMin !== null && issue.metaData.temperatureMin !== undefined) {
          issue.assignment.temperatureMin = parseInt(issue.metaData.temperatureMin);
        } else {
          issue.assignment.temperatureMin = null;
        }
      }

      if(issue.assignment.temperatureMax === null || issue.assignment.temperatureMax === undefined) {
        if(issue.metaData.temperatureMax !== null && issue.metaData.temperatureMax !== undefined) {
          issue.assignment.temperatureMax = parseInt(issue.metaData.temperatureMax);
        } else {
          issue.assignment.temperatureMax = null;
        }
      }
    }
    let sortedSeenBy = mapSeenBy(issue, obj.seenBy || []);
    let fuelLevel = null;
    if (issue.type === 'LOW_FUEL_ALARM' && measurement.engineState.fuelTankSize && issue.metaData.fuelLevel !== 'null') {
      fuelLevel = ((issue.metaData.fuelLevel || 0) / (measurement.engineState.fuelTankSize || 1)) * 100;
    } else if (measurement.engineState) {
      fuelLevel = (measurement.engineState.fuelPercentage || measurement.engineState.fuelPercentage === 0) ? measurement.engineState.fuelPercentage : null;
    }
    issues.push({
      issue: issue,
      id: issue.id,
      orderId: issue.assignment ? issue.assignment.businessId : "No order",
      leg: issue.leg,
      legId: issue.leg ? issue.leg.businessId : "Unknown",
      trailerId: issue.trailer ? issue.trailer.businessId : "Unknown",
      customerId: (issue.assignment && issue.assignment.customer) ? issue.assignment.customer.businessId : "Unknown",
      driver: (((issue.leg || {}).driver || {}).contactInfo || {}).name || "Unknown",
      division: (((issue.leg || {}).driver || {}).division || {}).businessId || "UNK",
      status: measurement.positionState && measurement.positionState.moving !== undefined ? measurement.positionState.moving : null,
      address: measurement.positionState && measurement.positionState.position !== undefined ? measurement.positionState.position : "Unknown",
      companyName: issue && issue.metaData ? issue.metaData.companyName : null,
      companyAddress: issue && issue.metaData ? issue.metaData.companyAddress : null,
      company: measurement.detentionState && measurement.detentionState.company !== undefined ? measurement.detentionState.company.name : null,
      fuelLevel: (fuelLevel || fuelLevel === 0) ? Math.round(fuelLevel) : null,
      detentionState: measurement && measurement.detentionState ? { ...measurement.detentionState, ...{ location: measurement.positionState.position } } : {},
      metaData: issue && issue.metaData ? issue.metaData : {},
      detentionReason: (issue && issue.metaData && issue.metaData.type) ? issue.metaData.type : "Unknown",
      alarmCode: (issue && issue.metaData && issue.metaData.code) ? issue.metaData.code : "Unknown",
      alarmDescription: (issue && issue.metaData && issue.metaData.description) ? issue.metaData.description : "Unknown",
      alarmAction: (issue && issue.metaData && issue.metaData.action) ? issue.metaData.action : "Unknown",
      companyId: (issue && issue.metaData && issue.metaData.companyId) ? issue.metaData.companyId : null,
      minTemp: issue.assignment ? issue.assignment.temperatureMin : "Unknown",
      maxTemp: issue.assignment ? issue.assignment.temperatureMax : "Unknown",
      setpoint: measurement.temperatureState.setTemperature !== undefined ? measurement.temperatureState.setTemperature : null,
      returnAir: measurement.temperatureState.actualTemperature !== undefined ? measurement.temperatureState.actualTemperature : null,
      modeC: issue.assignment ? issue.assignment.unitMode === "CONTINUOUS" ? "C" : "CS" : "NO DATA",
      modeR: measurement.modeState.unitMode !== undefined ? measurement.modeState.unitMode === "CONTINUOUS" ? "C" : "CS" : null,
      powerC: issue.assignment ? issue.assignment.temperatureControlled === true ? "On" : "Off" : "NO DATA",
      powerR: measurement.powerState.power !== undefined ? measurement.powerState.power === true ? "On" : "Off" : null,
      alerts: (notification.alerts || null),
      notified: notification.notified || null,
      latestWarning: latestWarningNotification,
      o_notified: notification.o_notified || null,
      latestEscalation: latestEscalationNotification,
      notifications: [],
      latestNotification: notification,
      snoozed: notification.snoozed,
      nextEscalationTime: obj.nextEscalationTime ? new Date(obj.nextEscalationTime) : null,
      resolved: RESOLVED_STATES.indexOf(issue.state) !== -1 ? issue.endTime : false,
      action: "",
      seenByBefore: sortedSeenBy.seenBeforeResolution,
      seenByAfter: sortedSeenBy.seenAfterResolution,
      closed: obj.closed,
      comments: obj.comments,
      dbIssue: issue,
      snoozedIssue: obj.snoozedIssue,
      silentUnit: (issue.type === "SILENT" && RESOLVED_STATES.indexOf(issue.state) === -1) ? true : (issue.type === "SILENT") ? false : null,
      detained: (issue.type === "DETENTION" && RESOLVED_STATES.indexOf(issue.state) === -1) ? true : (issue.type === "DETENTION") ? false : null,
      inactive: (issue.type === "INACTIVITY" && RESOLVED_STATES.indexOf(issue.state) === -1) ? true : (issue.type === "INACTIVITY") ? false : null,
      inactiveInYard: (issue.type === "INACTIVE_IN_YARD" && RESOLVED_STATES.indexOf(issue.state) === -1) ? true : (issue.type === "INACTIVE_IN_YARD") ? false : null,
      created: formatDate(new Date(issue.startTime)),
      eventDate: formatDate(new Date(issue.endTime)),
      duration: getDifferenceBetweenDates(issue.startTime, issue.relevant ? getCurrentDate() : issue.endTime),
      lastDepartureDate: (issue.metaData || {}).lastDepartureDate ? formatDate(getDateWithOffset(issue.metaData.lastDepartureDate, issue.metaData.lastDepartureDateOffset), true) : "Unknown",
      lastArrivalDate: (issue.metaData || {}).lastArrivalDate ? formatDate(new Date(issue.metaData.lastArrivalDate)) : "Unknown",
      beginning: issue.type === "SILENT" ? formatDate(addSilentUnitThresholdToDate(new Date(issue.startTime))) : formatDate(new Date(issue.startTime)),
      type: issueTypeMap(issue.type),
      state: issue.state,
      onClickState: issue.assignment ? { trailerId: issue.trailer.id, assignmentId: issue.assignment.id, isMaintenance } : { trailerId: issue.trailer.id, isMaintenance },
      truckPosition: (issue && issue.metaData && issue.metaData.truckPosition) || "Unknown",
      trailerPosition: (issue && issue.metaData && issue.metaData.trailerPosition) || "Unknown",
      stopCompanyId: (issue && issue.metaData && issue.metaData.stopCompanyId) || "Unknown",
      companyBusinessId: (issue && issue.metaData && issue.metaData.companyBusinessId) || "Unknown",
      distance: issue && issue.metaData && issue.metaData.distance
    });
  });
  return issues;
}

export function mapReportLocationIssuesFromResponse(data) {
  return data.map(obj => {
    const sortedSeenBy = mapSeenBy(obj.issue, obj.seenBy || []);

    return {
      id: obj.issue.id,
      issue: obj.issue,
      trailerId: obj.issue.trailer.businessId,
      orderId: obj.issue.assignment.businessId,
      driver: obj.driver.contactInfo.name,
      division: obj.division.businessId,
      created: formatDate(new Date(obj.issue.startTime)),
      truckPosition: obj.truckPosition,
      truckUpdated: obj.truckUpdated,
      trailerPosition: obj.trailerPosition,
      trailerUpdated: obj.trailerUpdated,
      distance: obj.distance,
      seenByBefore: sortedSeenBy.seenBeforeResolution,
      seenByAfter: sortedSeenBy.seenAfterResolution,
      comments: obj.comments,
      onClickState: { trailerId: obj.issue.trailer.id, assignmentId: obj.issue.assignment.id },
      action: ""
    }
  }
  );
}

export function mapReportIssuesFromResponse(issueData, reportStateData) {
  return issueData.map(entity => {
    const sortedSeenBy = mapSeenBy(entity.issue, entity.seenBy || []);
    const issue = entity.issue;
    const trailerReportState = (reportStateData.find(stateEntity => stateEntity.state.trailer.id === issue.trailer.id) || {}).state;

    return {
      issue,
      trailerId: issue.trailer.businessId,
      orderId: issue.assignment ? issue.assignment.businessId : 'No order',
      created: issue.startTime,
      formattedCreated: formatDate(new Date(issue.startTime)),
      driver: entity.driver ? entity.driver.contactInfo.name : 'Unknown',
      division: entity.division ? entity.division.businessId : "",
      stopCompanyId: trailerReportState.stopCompanyId,
      companyId: (trailerReportState.company || {}).businessId || "UNKNOWN",
      fuelLevel: trailerReportState.fuelLevel ? trailerReportState.fuelLevel : '0',
      reportCreated: trailerReportState.trailerReport.time,
      lastKnownAddress: trailerReportState.lastKnownAddress ? trailerReportState.lastKnownAddress : "Unknown",
      lastDepartureDate: trailerReportState.lastDepartureDate ? formatDate(getDateWithOffset(trailerReportState.lastDepartureDate, trailerReportState.lastDepartureDateOffset), true) : "Unknown",
      comments: entity.comments,
      seenByBefore: sortedSeenBy.seenBeforeResolution,
      seenByAfter: sortedSeenBy.seenAfterResolution,
      onClickState: issue.assignment ? { trailerId: issue.trailer.id, assignmentId: issue.assignment.id } : { trailerId: issue.trailer.id }
    };
  });
}

